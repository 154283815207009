<template>

    <a-layout style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style=";background-color:#fff;width:calc(100% - 32px);padding-top:20px; min-width:fit-content;min-height:calc(100% - 48px)">
                <a-layout style="height:100%;background:#fff">


                    <a-layout-content style="background-color:#fff;padding:20px 20px 16px;width:1400px;">
                        <a-form-model ref="ruleForm" :model="formModel" :rules="rules">

                            <a-form-item label="奖励名称" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form-model-item has-feedback prop="name">
                                    <a-input placeholder="奖励名称" type="text" style="width:700px" v-model="formModel.name"></a-input>
                                </a-form-model-item>
                            </a-form-item>
                            <a-form-item label="开始时间"  style="margin-bottom:0px;"
                                         prop="beginTime"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <a-form-model-item prop="beginTime">
                                    <a-date-picker v-model="formModel.beginTime"
                                                   show-time
                                                   type="date"
                                                   :disabled-date="disabledStartDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择开始时间"
                                                   style="width:700px"
                                                   @openChange="handleStartOpenChange" />
                                </a-form-model-item>
                            </a-form-item>

                            <a-form-item label="结束时间"     style="margin-bottom:0px;"                                             
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperCol">
                                <a-form-model-item prop="endTime">
                                    <a-date-picker show-time
                                                   style="width:700px"
                                                   type="date"
                                                   :disabled-date="disabledEndDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择结束时间"
                                                   :open="endOpen"
                                                   @openChange="handleEndOpenChange"
                                                   v-model="formModel.endTime" />
                                </a-form-model-item>
                            </a-form-item>

                            <a-form-item label="数量" style="margin-bottom:00px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <a-form-model-item has-feedback prop="linkman">
                                    <a-input placeholder="数量" v-model="formModel.count" style="width: 700px
"></a-input>
                                </a-form-model-item>

                            </a-form-item>
                            <a-form-item label="金额" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <a-form-model-item has-feedback prop="linkman">
                                    <a-input placeholder="金额" v-model="formModel.cash" style="width: 700px
"></a-input>

                                </a-form-model-item>

                            </a-form-item>
                            <a-form-item label="奖励图片" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-upload name="image"
                                          :data="ParamData"
                                          list-type="picture-card"
                                          class="avatar-uploader"
                                          action="/FileServer/Upload"
                                          :remove="removeImg"
                                          :fileList="fileList"
                                          @change="imgChange">
                                    <div v-if="fileList.length < 1">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">
                                            上传奖励图片
                                        </div>
                                    </div>
                                </a-upload>
                            </a-form-item>
                            <a-form-item label="奖品介绍" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <Rich v-bind:value.sync="formModel.description" style="width:100%;"></Rich>
                            </a-form-item>

                        </a-form-model>
                    </a-layout-content>

                    <a-layout-footer style="height:64px;padding:0 0 16px 0 ;background-color:#fff;">
                        <a-row>
                            <a-col :span="12">
                                <a-row typeof="flex" justify="space-around" class="textal_r">
                                    <a-col :span="12">
                                        <a-button size="large" type="primary" @click="save('ruleForm')">保存</a-button>
                                    </a-col>   <a-col :span="6">
                                        <a-button size="large" centered Vertically @click="cancle">取消</a-button>

                                    </a-col>

                                </a-row>
                            </a-col>
                        </a-row>
                    </a-layout-footer>
                </a-layout>

            </div>

        </a-layout-content>

   
    </a-layout>

</template>
<style>
    .bm-view {
        width: 100%;
        height: 700px;
    }
</style>

<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import Rich from "@/components/RichEditor.vue";
   import { message } from 'ant-design-vue'
    import util from "@/Plugin/util.js";    
//import { fail } from "assert";
    export default {
        name: "SiteDetail",
        components: {
            Rich
        },
        data() {
            return {     
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 21 },
                    },
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },            
                endOpen: false,
                fileList:[],
                formModel: {
                    id: this.$route.query.ID,
                    activityID: this.$route.query.ActivityID,
                    prizetype: this.$route.query.PrizeType,
                    name: '',      
                    count: 0,
                    cash:0,
                    //fileList: [], 
                    beginTime: "",//开始时间
                    endTime: "",//结束时间
                    pic: "",
                    description:""

                },
                rules: {
                    name: { validator: this.validateUserName, trigger: 'change', required: true },
                    linkman: { validator: this.validateUserName, trigger: 'change', required: true },
                    address: { validator: this.validateUserName, trigger: 'change', required: true },
                    beginTime: { validator: this.validateUserName, message: "请输入开始时间", required: true, trigger: "change" },
                    endTime: { validator: this.validateUserName, message: "请输入结束时间", required: true, trigger: "change" }
                }

            };
        },

        methods: {                  
            disabledStartDate(startValue) {
                const endValue = this.formModel.endTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.formModel.beginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {

                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {

                this.endOpen = open;
            },

            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.fileList = info.fileList;


            },
            removeImg: function (data) {
                console.log(data);  

            },
            validateUserName: function (rule, value, callback) {
       
                if (value === '') {
                    callback(new Error('该字段不能为空'));
                } else {
                    callback();
                }
            },
            validatelinkphone: function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('该字段不能为空'));
                } else {
                    if (util.GetPhone(value) === '') {
                        callback(new Error('请输入正确的联系电话'));
                    } else {
                        callback();
                    }
                }
            },
  
            getData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetAwardDetail",
                    data: {
                        id: self.formModel.id
                    },
                    OnSuccess: function (data) {

                        //console.log(data);
                        self.formModel.activityID = data.data.ActivityID;
                        self.formModel.prizetype = data.data.PrizeType;
                        self.formModel.name = data.data.Name;
                        self.formModel.count = data.data.Count;
                        self.formModel.cash = data.data.Cash;
                        self.formModel.beginTime = util.TimeStamptoDateTime(data.data.BeginTime, 'yyyy-MM-dd hh:mm:ss');//  data.data.BeginTime;
                        self.formModel.endTime = util.TimeStamptoDateTime(data.data.EndTime, 'yyyy-MM-dd hh:mm:ss');// data.data.EndTime;
                        self.formModel.description = data.data.Extend.Description;
                        //// self.pos = data.data.

                        if (data.data.Extend != null && data.data.Extend.Pic != null) {
                            self.fileList = [{
                                name: data.data.Extend.Pic,
                                response: data.data.Extend.Pic,
                                status: "done",
                                uid: "0",
                                url: data.data.Extend.Pic
                            }];
                        }


                    }
                };
                http.Post(op);
            },

            save: function (formName) {
                var self = this;
                this.$refs[formName].validate(valid => {
                    console.log(11111);
                    if (valid) {
                        console.log(self.formModel.beginTime);

                        var obj = {
                            ID: self.formModel.id,
                            ActivityID: self.formModel.activityID,
                            Name: self.formModel.name,
                            PrizeType: self.formModel.prizetype,
                            BeginTime: self.formModel.beginTime,
                            EndTime: self.formModel.endTime,
                            Count: self.formModel.count,
                            Cash: self.formModel.cash,
                            Pic: self.fileList.length > 0 ? self.fileList[0].response : "",
                            Description: self.formModel.description
                        };

                        console.log(obj);
                        //return;
                        var op = {
                            url: "/MarketingModule/M2Activity/UpdatePrize",
                            data: {
                                ID: self.formModel.id,
                                ActivityID: self.formModel.activityID,
                                Name: self.formModel.name,
                                PrizeType: self.formModel.prizetype,
                                BeginTime: self.formModel.beginTime,
                                EndTime: self.formModel.endTime,
                                Count: self.formModel.count, 
                                Cash: self.formModel.cash,
                                Pic: self.fileList.length > 0 ? self.fileList[0].response : "",
                                Description:self.formModel.description
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                if (data.data) {
                                    var display = "新增成功";
                                    if (self.formModel.id != null && self.formModel.id != -1) {
                                        display = "编辑成功";
                                    }

                                    message.success(
                                        display,
                                        1.5,
                                        function () {
                                            self.$router.push({
                                                name: "M2Activity_PrizeList", query: {
                                                    ActivityID: self.formModel.activityID
                                                }
                                            })
                                        }
                                    );
                                }
                            }
                        };

                        http.Post(op);
                    } else {
                        return false;
                    }
                });
            },
            cancle() {
                var self = this;
                self.$router.push({
                    name: "ShopManage_ShopManage", query: {
                        showName: "SiteList"
                    }
                })
            }
        },
        mounted() {
            var self = this;
            if (self.formModel.id != null && self.formModel.id != -1) {
                self.getData();
            }

        }

    };
</script>



